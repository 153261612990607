.prtnr {
	background: #fff;
	/*
	border-top: 3px solid #d9d9d9;
	*/
	margin: 0 auto;
	padding: .5em 0;

	&_wrap {
		align-content: center;
		align-items: center;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 auto;
		max-width: var(--widthL);
		padding: 1em 0;
	}

	svg {
		fill: #c60;
		height: 100px;
		width: 100px;
	}

	p {
		/*
		border: 2px solid #333;
		font-size: 1.1em;
		*/
		line-height: 1.4;
		max-width: 100%;
		padding: .25rem .5rem;
		text-align: left;
		vertical-align: middle;

		@media (--med) {
			font-size: 1.6em;
			/*
			font-size: 1.5vw;
			*/
		}

		/*
		&:first-child {
			text-align: center;
		}
		*/

		&:last-child {
			flex: 1;
			margin-top: 0;

			@media (--med) {
				text-align: left;
			}

		}
	}

	a {
		border: none;
		color: var(--color-dkblue-sm);
		font-weight: 600;
		&.readmore {
			font-variant: small-caps;
			padding-left: 1em;
			padding-right: 1em;
			white-space: nowrap;
			&:after {
				content: "\0020\00bb";
			}
		}

		&:focus,
		&:hover {
			text-decoration: underline;
		}
	}

	img {
		/*
		border: 3px solid var(--mast1);
		border-radius: 50%;
		*/
		vertical-align: middle;
	}

	h2 {
		color: var(--mast1);
		font-weight: 600;
		margin: .25em auto 0;
		max-width: var(--widthL);
		text-align: center;
		@media (--med) {
			text-align: left;
			padding: 0 0 0 1em;
		}
	}

	@media (--med_lg) {
		padding: 0 10vw;
	}

	@media (--lg) {
		padding: 0 15vw;

		img {
			margin: 0 .5em;
		}
	}
}

.hp {

	&__cards {
		background-color: var(--color-yellow);
		background-image: linear-gradient(180deg, var(--color-yellow), #fff);
		/*
		border-top: 3px solid #fff;
		*/
		margin-top: 0;
		padding: 2em 0;

		h1 {
			color: #333;
			/*
			font-size: calc(.9em + 1.2vw);
			*/
			font-size: 1.2em;
			font-weight: 400;
			line-height: 1.5;
			margin: .5em auto;
			max-width: 25em;
			padding: 0 1rem;
			text-align: center;

			@media (--med) {
				font-size: 1.8em;
			}
		}

		h2,
		h3 {
			font-weight: 600;
			margin: 0;
			text-align: center;
		}

		h2 {
			color: #333;
			font-size: 1.8em;
			letter-spacing: .25em;
		}

		h3 {
			/*
			border-bottom: 3px solid color(var(--mast1) w(90%));
			border-bottom: 3px solid #e8e8ef;
			*/
			border-bottom: .125rem solid var(--color-yellow);
			color: var(--color-dkorange);
			font-size: 1em;
		}

		p {
			padding: .125em .5em;
			text-align: center;
		}

		> ul {
			align-content: center;
			align-items: stretch;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}

		li,
		.card_txt {
			display: flex;
			flex-direction: column;
		}

		li {
			background-color: #fff;
			border: .125rem solid var(--color-yellow);
			margin: 1rem .25rem;
			max-width: 300px;
			min-width: 300px;
			min-height: 280px;
			overflow: hidden;
			/*
			padding: 1em 1em 1.5em;
			*/
		}

		.card_txt {
			flex-grow: 1;
			padding: 0 1rem;

			> * + * {
				margin-top: .5rem;
			}

			&:last-child {
				margin-top: auto;
			}

			&:nth-last-child(2) {
				margin-bottom: .75rem;
			}
		}

		.card_img {
			background: color(var(--color-yellow) l(85%));
			background-image: linear-gradient(180deg, #fff 0, #fff 50%, color(var(--color-yellow) l(82%)) 50%, var(--color-yellow) 100%);
			/*
			padding: 1rem 0 0;
			*/
			text-align: center;

			img {
				border: .5rem solid var(--color-yellow);
				border-radius: 50%;
				height: 200px;
				margin: auto;
				width: 200px;
			}
		}
	}

	&__benefits {
		background-position: bottom;
		background-repeat: no-repeat;
		background-size: cover;
		border-top: 3px solid var(--color-blue4);
		margin: 0;
		padding: 5vw 0;

		&.loaded {
			background-image: url("/site/templates/static/i/skyclouds.jpg");
		}

		h1 {
			color: var(--mast2);
		}

		* + * {
			margin: auto;
			max-width: 20rem;
		}

		ul {
			list-style: none;
			margin: 1em auto;

			@media (--med) {
				font-size: 1.2rem;
			}

			li {
				background-color: #fff;
				background-image: url("/site/templates/static/s/checkbox.svg");
				background-position: 0 50%;
				background-repeat: no-repeat;
				background-size: 20px;
				color: var(--color-text);
				margin: auto;
				padding-left: 30px;
				text-align: left;
			}
		}

		p {
			text-align: center;
		}
	}

	&__cards,
	&__benefits {

		a {
			background-color: #fff;
			border: none;
			color: var(--color-blue5);
			display: inline-block;
			font-size: 1.2em;
			font-weight: 600;
			/*
			margin: .5em 0 0;
			*/
			padding: 1em 2em;
			transition: all .25s;
			&:focus,
			&:hover {
				background: var(--color-green);
				color: #fff;
			}
		}
	}

	&__wrap {
		background-color: rgba(255, 255, 255, .9);
		margin: 0 1em;
		max-width: var(--widthL);
		padding: 1.5em 0;
		text-align: center;

		@media (--med) {
			background-color: unset;
			background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .85) 25%, rgba(255, 255, 255, .85) 75%, rgba(255, 255, 255, 0) 100%);
			margin: auto;
		}

		&.card {
			display: flex;
			flex-direction: column;
		}
	}
}

/*
main > .hero,
main > .prtnr,
*/
main > .hp__surveys,
main > .hp__benefits {
	margin: 0 auto;
	max-width: var(--widthXL);
}
